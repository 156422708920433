import getGoogleIDs, {debugGoEvent} from './modules/tracking/getGoogleIDs';
import {isSemUser, getQSValue, isMTUser} from './modules/tracking';
import {getCookie, setCookie} from './modules/ringpoolAuto/cookies';
import {getS17Value, getS7Value} from './modules/tracking/getSValues';

// const TRACKING_CLASS = 'is-tracking';
const MT_USER_COOKIE_NAME = 'MTUSER_S7';
// const MT_USER_QUERY_PARAMETER = 'customnative6';
// const isTracking = document.body.classList.contains(TRACKING_CLASS);
const urlBase = window.site_settings?.admin_ajax || '';
window.tracking_request_complete = false;

// console.debug('Tracking module')
// console.debug(urlBase);

/**
 * Fetch s1 value from admin-ajax
 * @param {function} successCallback - function to call if request was successful
 * @param {function} failureCallback - function to call if request was unsuccessful
 */
function loadTrackingValuesFromServer(successCallback, failureCallback) {
    const s17 = getS17Value(),
        s7 = getS7Value();
    let url;
    const urlParamsObj = {
        action: 'get_tracking',
        referrer: encodeURIComponent(document.referrer),
        s17: s17 || 'SL_s17_not_set',
    };

    if (isSemUser()) { // Check if SEM User
        urlParamsObj.cfsource = 'sem';
        urlParamsObj.s1 = getQSValue('s1') || getCookie('cf_dg_clk_id') + '.' + getCookie('ckm_request_id');
        urlParamsObj.cpid = getQSValue('cpid');
    } else if(isMTUser()) { // Check if Media Transformation User
        urlParamsObj.s7 = s7 || null;
        urlParamsObj.s1 = getQSValue('s1') || getCookie('cf_dg_clk_id') + '.' + getCookie('ckm_request_id');
        urlParamsObj.cpid = getQSValue('cpid') || null;
    }
    const urlParams = (new URLSearchParams(urlParamsObj)).toString();
    url = `${urlBase}?${urlParams}`;

    fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(s1Data => {
            // console.debug('s1Data', s1Data);
            window.cf_dg_clk_id = s1Data.cf_dg_clk_id;
            window.ckm_request_id = s1Data.ckm_request_id;

            // updated Cookied Values
            setCookie('cf_dg_clk_id', s1Data.cf_dg_clk_id);
            setCookie('ckm_request_id', s1Data.ckm_request_id);

            // if mt user set s7 cookie
            if(isMTUser()) {
                const s7Value = getS7Value() || null;
                setCookie(MT_USER_COOKIE_NAME, s7Value);
            }

            if (typeof (successCallback) === 'function') {
                successCallback();
            }
        })
        .catch(error => {
            console.error('Fetch Error =\n', error);
            if (typeof (failureCallback) === 'function') {
                failureCallback();
            }
        });
}

/**
 * Append SEM query parameters (cfsource, s1, and cpid) to current url
 * @param s1Value
 * @param cpidValue
 */
function appendSemQueryParameters(s1Value, cpidValue, s7Value) {
    if(s1Value.length > 1) {
        let currentUrl = window.location.href;

        if(currentUrl.indexOf('source=sem') < 0) {
            let state = history.state, title = document.title,
                prefix = window.location.href.indexOf('?') < 0 ? '?' : '&',
                url = window.location.origin + window.location.pathname + `${prefix}source=sem&s1=${s1Value}`;

            if(cpidValue?.length > 0) {
                url += `&cpid=${cpidValue}`;
            }

            if(isMTUser()) {
                url += `&s7=${s7Value}`;
            }

            history.pushState(state, title, url);
        }
    }
}

/**
 * Append SEM query parameters (cfsource, s1, and cpid) to all internal urls
 * @param s1Value
 * @param cpidValue
 */
function appendSemParamsToAllHrefs(s1Value, cpidValue, s7Value) {
    if(s1Value.length > 1) {
        const currentDomain = window.location.hostname,
            anchors = document.querySelectorAll('a:not([href^="cfsource"]:not([href^="tel:"]):not([href^="mailto:"]):not([data-ga="referral-cta"]))');
        let queryString = `source=sem&s1=${s1Value}`;

        if(cpidValue?.length > 0) {
            queryString += `&cpid=${cpidValue}`;
        }

        if(isMTUser()) {
            queryString += `&s7=${s7Value}`;
        }

        anchors.forEach(anchor => {
            if ((anchor.href.includes(currentDomain) || anchor.href.indexOf('/') === 0) && !anchor.href.includes('#')) {
                anchor.href += anchor.href.includes('?') ? `&${queryString}` : `?${queryString}`;
            }
        });
    }
}


/**
 * Append SEM query parameters (cfsource, s1, and cpid) to current url and all internal urls
 */
function updateHrefsAndQueryParamsForSem() {
    const s1Value = getCookie('cf_dg_clk_id') + '.' + getCookie('ckm_request_id'),
        cpidValue = getCookie('CF_CPID') ? getCookie('CF_CPID') : '',
        s7Value = getCookie(MT_USER_COOKIE_NAME) ? getCookie(MT_USER_COOKIE_NAME) : getQSValue('s7');
    appendSemQueryParameters(s1Value, cpidValue, s7Value);
    appendSemParamsToAllHrefs(s1Value, cpidValue, s7Value);
}

let init = () => {
    // required for child pixel
    window.getCookie = getCookie;
    const cf_dg_clk_id = getCookie('cf_dg_clk_id') || null;

    window.dataLayer = window.dataLayer || [];

    window.cl_object = {
        runner: null,
    };

    // register callback for autoringpool script, so we can manually re-run the ringpool ourselves if needed
    window.cl_on_complete = function (cl_runner) {
        window.cl_object.runner = function () {
            cl_runner();
        }
        cl_runner();
    };

    let s1QueryParam = getQSValue('s1');
    if(s1QueryParam && s1QueryParam.length > 1) {
        let s1Values = s1QueryParam.split('.');
        if(s1Values.length === 2) {
            window.cf_dg_clk_id = s1Values[0];
            window.ckm_request_id = s1Values[1];
            setCookie('cf_dg_clk_id', window.cf_dg_clk_id);
            setCookie('ckm_request_id', window.ckm_request_id);
        }
    }

    // we have a cookied user! no network request required
    if (cf_dg_clk_id !== '' && cf_dg_clk_id !== null && getQSValue('cfsource') !== 'sem') {
        window.cf_dg_clk_id = getCookie('cf_dg_clk_id');
        window.ckm_request_id = getCookie('ckm_request_id');
        // console.debug(window.cf_dg_clk_id, window.ckm_request_id);
        debugGoEvent({
            'event': 'ifTrackingNotSEM',
        });

        if (isSemUser()) {
            updateHrefsAndQueryParamsForSem();
        }

        sendCFEvent(window.cf_dg_clk_id, window.ckm_request_id);
        gago();
        window.tracking_request_complete = true;
        window.dispatchEvent(new CustomEvent('tracking_request_complete'));
    } else {
        debugGoEvent({
            'event': 'TrackingSEM',
        });
        // this is a new user, we have to request the tracking values from the admin-ajax endpoint
        loadTrackingValuesFromServer(
            function () {
                if (isSemUser()) {
                    updateHrefsAndQueryParamsForSem();
                }

                sendCFEvent(window.cf_dg_clk_id, window.ckm_request_id);
                gago();
                window.tracking_request_complete = true;
                window.dispatchEvent(new CustomEvent('tracking_request_complete'));
            },
            function () {
                // console.debug('Error getting S1 Values');
            }
        );
    }

    // send GTM event with s1 value
    // this allows us to tie GTM/GA events to a particular CF session
    function sendCFEvent(cf, ckm) {
        debugGoEvent({
            'event': 'sendCFEvent',
        });
        let data = {
            'event': 'CF_TRACKING',
            's1-value': cf + '.' + ckm,
        };

        if(isMTUser()) {
            data['s7-value'] =  getS7Value();
        }

        // console.debug('sendCFEvent', data);
        window.dataLayer.push(data);
    }

    // call admin-ajax gago function with analytics/optimize data
    function gago() {
        debugGoEvent({
            'event': 'triggerGAGO',
        });
        getGoogleIDs('gago')
            .then((googleIds) => {
                let clientId = null;
                let variationId = null;
                let experimentId = null;

                if (googleIds.optimizeData !== null) {
                    experimentId = googleIds.optimizeData.experimentId;
                    variationId = googleIds.optimizeData.variationId;
                } else {
                    experimentId = window.site_settings.experiment.id;
                    variationId = window.site_settings.experiment.variant;
                    debugGoEvent({
                        'event': 'gagoOptimizeTrackingValuesAreNull',
                    });
                }

                if (googleIds.analyticsData !== null) {
                    clientId = googleIds.analyticsData.clientId;
                } else {
                    clientId = document.cookie.match(/_ga=(.+?);/)[1]?.split('.')?.slice(-2)?.join('.');
                    if(!clientId) {
                        clientId = null;
                    }
                    debugGoEvent({
                        'event': 'gagoAnalyticsClientIdLoadedFromCookies',
                    });
                }
                let s7Value = getS7Value() || null;
                let gagoData = {
                    action: 'gago',
                    sub_id: window.cf_dg_clk_id + '.' + window.ckm_request_id,
                    google_analytics_client_id: clientId,
                    google_optimize_experiment_id: experimentId,
                    google_optimize_experiment_variation_id: variationId,
                    referrer: document.referrer,
                    page_type: getS17Value(),
                    page_url: document.location.href,
                    s7: s7Value,
                };

                try {
                    const fetchUrl = `${urlBase}?${(new URLSearchParams(gagoData)).toString()}`;
                    fetch(fetchUrl).then((response) => console.debug(response));
                } catch (error) {
                    console.error(error);
                }

                debugGoEvent({
                    'event': 'gagoTrackingCompleted', googleIds,
                });
            }).catch((error) => {
                debugGoEvent({
                    'event': 'triggerGAGOFailed',
                    'error': error,
                });
            });
    }
};

// if (!isTracking) {
//     init = () => {
//         // required for child pixel
//         window.getCookie = getCookie;
//
//         if (getCookie('cf_dg_clk_id') === '' || getQSValue('cfsource') === 'sem') {
//             loadTrackingValuesFromServer();
//         } else {
//             // Check if SEM User for non-tracking templates and update hrefs & query params
//             document.addEventListener('DOMContentLoaded', function () {
//                 if (isSemUser()) {
//                     updateHrefsAndQueryParamsForSem();
//                 }
//
//                 if(isMTUser()) {
//                     const s7Value = getS7Value();
//                     setCookie(MT_USER_COOKIE_NAME, s7Value);
//                 }
//             });
//         }
//     };
// }

init();
